import React, { useEffect, useState } from 'react';
import IconClose from '../images/icon-close.svg';
import IconChevron from '../images/icon-chevron.svg';
import IconLink from '../images/icon-external.svg';
import IconPlay from '../images/icon-play.svg';
import { type ProjectType } from '../projects/data';
import useLayoutEffect from '../hooks/useIsomorphicLayoutEffect';

import gsap from 'gsap';

type GalleryViewProps = {
  data: ProjectType;
  active: boolean;
};

export const GalleryView = ({ data: project, active }: GalleryViewProps) => {
  const { source, title, description, tags, url, videoSrc, posterSrc, invertPlayButton } = project;
  const [detailsActive, setDetailsActive] = useState<boolean | undefined>(undefined);
  const [isPlaying, setPlaying] = useState<boolean>(false);

  const videoItem = (
    <video
      width='100%'
      muted
      loop
      playsInline
      poster={posterSrc}
      onClick={(event: any) => {
        if (event.target.paused) {
          event.target.play();
          setPlaying(true);
        } else {
          event.target.pause();
          setPlaying(false);
        }
      }}
      style={{ cursor: 'pointer' }}
    >
      <source src={videoSrc} type='video/mp4' />
    </video>
  );

  const item = videoSrc ? videoItem : source;

  const handleKeydown = (event: any) => {
    if (event.key === 'ArrowDown') {
      event.preventDefault();
      setDetailsActive(true);
    }

    if (event.key === 'ArrowUp') {
      event.preventDefault();
      setDetailsActive(false);
    }

    if (event.key === 'Escape') {
      event.preventDefault();
      setDetailsActive(false);
    }
  };

  useEffect(() => {
    document.documentElement.style.overflow = 'hidden';
    document.addEventListener('keydown', handleKeydown);
    return () => {
      document.documentElement.style.overflow = 'auto';
      document.removeEventListener('keydown', handleKeydown);
    };
  }, []);

  useEffect(() => {
    active && (window as any).umami?.track('Project Detail', { project: project.title });
  }, [active]);

  useLayoutEffect(() => {
    let ctx = gsap.context(() => {
      gsap.fromTo(
        '.read-more-btn',
        {
          opacity: 0,
          y: -50,
          zIndex: -1,
        },
        {
          delay: 0.2,
          opacity: 1,
          y: 0,
          duration: 0.6,
        }
      );
    }, '.gallery-view');
    return () => ctx.revert();
  }, []);

  return (
    <div className='gallery-view'>
      <div
        role='presentation'
        className={['image-wrapper', detailsActive ? 'details-active' : ''].join(' ')}
      >
        {item}
        {url && !videoSrc && (
          <a className='link-overlay' href={url} target='_blank' rel='noopener noreferrer'>
            <img width={20} height={20} src={IconLink} alt='' />
          </a>
        )}
        {videoSrc && (
          <img
            className='video-overlay'
            width={32}
            height={32}
            src={IconPlay}
            alt=''
            style={{
              opacity: isPlaying ? 0 : invertPlayButton ? 0.6 : 1,
              pointerEvents: 'none',
              filter: invertPlayButton ? 'invert(1)' : 'invert(0)',
            }}
          />
        )}
        {!detailsActive && (
          <button
            className='read-more-btn'
            onClick={() => {
              setDetailsActive(true);
              (window as any).umami.track('Read more', { project: project.title });
            }}
          >
            Mehr erfahren{' '}
            <span>
              <img width={20} height={20} src={IconChevron} alt='' />
            </span>
          </button>
        )}
      </div>

      {/* Details Card */}
      <div
        className={[
          'paper',
          detailsActive ? 'details-active' : '',
          detailsActive === false ? 'details-inactive' : '',
        ].join(' ')}
      >
        {url ? (
          <a className='project-link' href={url} target='_blank' rel='noopener noreferrer'>
            <h2>{title}</h2>
            <img className='link-icon' width={24} height={24} src={IconLink} alt='' />
          </a>
        ) : (
          <h2>{title}</h2>
        )}
        <p>{description}</p>

        <div className='badges-container'>
          {tags.map((tag) => (
            <div className='badge--info' project-color='#cc4e2e' key={tag}>
              {tag}
            </div>
          ))}
        </div>

        <button className='close-btn' onClick={() => setDetailsActive(false)}>
          <img width={20} height={20} src={IconClose} alt='Schließen' />
        </button>
      </div>
    </div>
  );
};
